<template>
	<div class="menu-container" @contextmenu.prevent>
		<!-- Parent menu -->
		<ul ref="parentMenu" class="menu">
			<template v-if="allowRelatedStudies">
				<li class="title">
					<span>Switch Series</span>
				</li>
				<li
					v-for="(study, i) in relatedStudiesWithImages"
					:key="i"
					class="child-item"
					@mouseover="e => openChildMenuDelayed(e, study)"
					@click="e => openChildMenu(e, study)"
					@mouseleave="closeChildMenu"
				>
					<span :style="studyDotStyle(study)" class="open-series-dot"></span>
					<span style="flex-grow: 1" @click.prevent>
						{{ study.patientName }} ({{ study.modality }}) on
						{{ study.studyDateTime | localizeDate({ forceUTC: false }) }} :
						{{ study.studyDescription }}
					</span>
				</li>
			</template>
			<li class="title">
				<span>Multi-Planar Reconstruction</span>
			</li>
			<li :class="{ disabled: mprLoading }" @click="viewMpr">
				<svg-icon v-if="mprLoading" icon="spinner-circle-thick" class="no-disable" spin />
				<svg-icon v-else icon="layout-mpr-3x1" class="no-disable" />
				<span style="flex-grow:1;margin-left:7px">
					{{ mprTitle }}
				</span>
			</li>
		</ul>
		<!-- Child menu -->
		<ul
			v-if="isChildOpen"
			ref="childMenu"
			class="menu"
			@mouseenter="e => openChildMenu(e, hoverStudy)"
			@mouseleave="closeChildMenu"
		>
			<li
				v-for="thumbnail in hoverStudy.imageData.thumbnails"
				:key="thumbnail.seriesId"
				:class="{ disabled: thumbnail.seriesId === activeSeries.seriesId }"
				@click="selectSeries(thumbnail.seriesId)"
			>
				<span :style="seriesDotStyle(thumbnail.seriesId)" class="open-series-dot"></span>
				<span style="flex-grow: 1">{{ thumbnail.seriesNumber }}: {{ thumbnail.simpleName }}</span>
			</li>
		</ul>
	</div>
</template>

<script>
import SvgIcon from '@components/SvgIcon'
import { mapGetters } from 'vuex'

export default {
	name: 'ViewerContextMenu',
	components: {
		SvgIcon,
	},
	props: {
		top: {
			type: Number,
			required: true,
		},
		left: {
			type: Number,
			required: true,
		},
		activeSeries: {
			type: Object,
			required: true,
		},
		allowRelatedStudies: {
			type: Boolean,
			default: false,
		},
		relatedStudies: {
			type: Array,
			required: true,
		},
		mprTitle: { type: String, required: true },
		mprLoading: Boolean,
		containerRef: {
			type: null, // HTMLElement is not an object
			default: null, // Requires default for eslint
		},
	},
	data() {
		return {
			isChildOpen: false,
			hoverTimeout: undefined,
			hoverStudy: undefined,
		}
	},
	computed: {
		...mapGetters(['allActiveSeriesIds']),
		relatedStudiesWithImages() {
			return this.relatedStudies.filter(study => study.imageData.thumbnails.length)
		},
		containerRect() {
			let rect = {
				left: 0,
				right: window.innerWidth,
				top: 0,
				bottom: window.innerHeight,
			}
			if (this.containerRef) {
				rect = this.containerRef.getBoundingClientRect()
			}
			return rect
		},
	},
	mounted() {
		this.addCloseEventHandlers()
		this.$refs.parentMenu.style.top = this.top + 'px'
		this.$refs.parentMenu.style.left = this.left + 'px'
		this.moveMenuOntoScreen(this.$refs.parentMenu)
	},
	methods: {
		isStudyActive(study) {
			return study.imageData.thumbnails.some(t => this.allActiveSeriesIds.includes(t.seriesId))
		},
		studyDotStyle(study) {
			const openSeries = study.imageData.thumbnails.find(t =>
				this.allActiveSeriesIds.includes(t.seriesId)
			)
			const style = {}
			if (openSeries) {
				style.backgroundColor = this.$store.getters.canvasColors[openSeries.seriesId]
			}
			return style
		},
		seriesDotStyle(seriesId) {
			if (!this.allActiveSeriesIds.includes(seriesId)) return
			return {
				backgroundColor: this.$store.getters.canvasColors[seriesId] || 'transparent',
			}
		},
		addCloseEventHandlers() {
			const handleEscape = e => {
				if (e.key === 'Escape') this.$emit('close')
			}
			document.addEventListener('keydown', handleEscape)
			const handleClick = e => {
				let outsideClick = !this.$refs.parentMenu.contains(e.target)
				if (this.$refs.childMenu)
					outsideClick = outsideClick && !this.$refs.childMenu.contains(e.target)
				if (outsideClick) this.$emit('close')
			}
			const downEvents = ['mousedown', 'touchstart', 'wheel']
			downEvents.forEach(e => document.addEventListener(e, handleClick))

			this.$once('hook:destroyed', () => {
				clearTimeout(this.hoverTimeout)
				downEvents.forEach(e => document.removeEventListener(e, handleClick))
				document.removeEventListener('keydown', handleEscape)
			})
		},
		moveMenuOntoScreen(menu) {
			this.$nextTick(() => {
				const offScreenX =
					Math.max(0, menu.getBoundingClientRect().right - this.containerRect.right) ||
					Math.min(0, menu.getBoundingClientRect().left - this.containerRect.left)
				const offScreenY =
					Math.max(0, menu.getBoundingClientRect().bottom - this.containerRect.bottom) ||
					Math.min(0, menu.getBoundingClientRect().top - this.containerRect.top)
				if (offScreenX) menu.style.left = menu.getBoundingClientRect().left - offScreenX + 'px'
				if (offScreenY) menu.style.top = menu.getBoundingClientRect().top - offScreenY + 'px'
			})
		},
		openChildMenu(e, study) {
			clearTimeout(this.hoverTimeout)
			this.hoverStudy = study
			this.isChildOpen = true
			this.$nextTick(() => {
				const child = this.$refs.childMenu
				if (!child) return
				const parentMenu = this.$refs.parentMenu.getBoundingClientRect()
				const parentMenuItem = e.target.getBoundingClientRect()
				const overlap = 4
				child.style.top = parentMenuItem.top + 'px'
				const isTooFarRight =
					parentMenu.right + child.offsetWidth - overlap > this.containerRect.right
				if (isTooFarRight) {
					const alignedLeft = parentMenu.left - child.offsetWidth + overlap + 'px'
					child.style.left = alignedLeft
				} else {
					const alignedRight = parentMenu.right - overlap + 'px'
					child.style.left = alignedRight
				}
				this.moveMenuOntoScreen(this.$refs.childMenu)
			})
		},
		openChildMenuDelayed(e, study) {
			const hoverDelay = 400
			clearTimeout(this.hoverTimeout)
			this.hoverTimeout = setTimeout(() => {
				this.openChildMenu(e, study)
			}, hoverDelay)
		},
		closeChildMenu() {
			const hideDelay = 100
			clearTimeout(this.hoverTimeout)
			this.hoverTimeout = setTimeout(() => {
				this.isChildOpen = false
				this.hoverStudy = undefined
			}, hideDelay)
		},
		selectSeries(seriesId) {
			if (seriesId === this.activeSeries.seriesId || !this.hoverStudy) return
			this.$emit('select', {
				studyId: this.hoverStudy.studyId,
				seriesId,
				clinicCode: this.$route.params.clinicCode,
			})
			this.$emit('close')
		},
		viewMpr(e) {
			this.$emit('view-mpr', e)
			this.$emit('close')
		},
	},
}
</script>

<style lang="scss" scoped>
@import '~@styles/_vars.scss';

.menu-container {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.menu {
	display: block;
	position: fixed;
	min-width: 150px;
	max-width: 90%;
	background: var(--viewer-menu-bg);
	color: var(--menu-color);
	list-style-type: none;
	border: 1px solid var(--divider);
	border-radius: 4px;
	user-select: none;
	max-height: 50vh;
	overflow-y: auto;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.title {
	font-size: 0.75em;
	opacity: 0.6;
	text-transform: uppercase;
	letter-spacing: 0.05em;
}

li.title:hover {
	background: transparent;
}

li {
	padding: 0 8px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	&:hover,
	&:active {
		background: var(--viewer-menu-item-hover-bg);
	}
}

li span {
	padding: 4px 16px 4px 0;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

li.child-item::after {
	content: '\25b8';
}

li.disabled {
	user-select: none;
	pointer-events: none;
	& > *:not(.no-disable) {
		opacity: 0.5;
	}
}

.child-item {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.open-series-dot {
	width: 10px;
	height: 10px;
	display: inline-block;
	border-radius: 50%;
	margin-right: 6px;
	padding: 0;
}
</style>
